/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to define the root routes.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

/* Relative Imports */
import AdminDashboardLayout from 'layout/AdminDashboardLayout';

/* Local Imports */
import { PAGE_ADMIN_DASHBOARD } from './paths';
import AuthGuard from './guards/AuthGuard';

// ----------------------------------------------------------------------

/* Admin Dashboard Module Imports */
const ManageRolePage = lazy(() =>
  import('views/admin-dashboard/roles/ManageRole')
);

const CreateRolePage = lazy(() =>
  import('views/admin-dashboard/roles/CreateRole')
);

const ManageCountryPage = lazy(() =>
  import('views/admin-dashboard/country/ManageCountry')
);

const CreateCountryPage = lazy(() =>
  import('views/admin-dashboard/country/CreateCountry')
);

const ManageCategoryPage = lazy(() =>
  import('views/admin-dashboard/category/ManageCategory')
);

const CreateCategoryPage = lazy(() =>
  import('views/admin-dashboard/category/CreateCategory')
);

const ManageUserPage = lazy(() =>
  import('views/admin-dashboard/users/ManageUser')
);

const CreateUserPage = lazy(() =>
  import('views/admin-dashboard/users/CreateUser')
);

const ManageTechnologyPage = lazy(() =>
  import('views/admin-dashboard/technology/ManageTechnology')
);

const CreateTechnologyPage = lazy(() =>
  import('views/admin-dashboard/technology/CreateTechnology')
);

const ManageClientPage = lazy(() =>
  import('views/admin-dashboard/client/ManageClient')
);

const CreateClientPage = lazy(() =>
  import('views/admin-dashboard/client/CreateClient')
);

const ManageProjectPage = lazy(() =>
  import('views/admin-dashboard/project/ManageProject')
);

const CreateProjectPage = lazy(() =>
  import('views/admin-dashboard/project/CreateProject')
);

const ManageUserProjectsPage = lazy(() =>
  import('views/admin-dashboard/user-project/ManageUserProjects')
);

const CreateUserProjectsPage = lazy(() =>
  import('views/admin-dashboard/user-project/CreateUserProjects')
);

const ManageLeadPage = lazy(() =>
  import('views/admin-dashboard/leads/ManageLead')
);

const CreateLeadPage = lazy(() =>
  import('views/admin-dashboard/leads/CreateLead')
);

const ManageLeadCategoryPage = lazy(() =>
  import('views/admin-dashboard/lead-category/ManageLeadCategory')
);

const CreateLeadCategoryPage = lazy(() =>
  import('views/admin-dashboard/lead-category/CreateLeadCategory')
);

const ManageLeadSourcePage = lazy(() =>
  import('views/admin-dashboard/lead-source/ManageLeadSource')
);

const CreateLeadSourcePage = lazy(() =>
  import('views/admin-dashboard/lead-source/CreateLeadSource')
);

// ----------------------------------------------------------------------

/**
 * assign components to routes
 *
 * @returns {array}
 */
const AdminDashboardRoutes = [
  {
    path: PAGE_ADMIN_DASHBOARD.root.relativePath,
    element: (
      <AuthGuard>
        <AdminDashboardLayout>
          <Outlet />
        </AdminDashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={PAGE_ADMIN_DASHBOARD.roles.absolutePath} />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.roles.relativePath,
        children: [
          {
            index: true,
            element: <ManageRolePage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.roles.create.relativePath,
            element: <CreateRolePage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.roles.edit.relativePath,
            element: <CreateRolePage />
          }
        ]
      },
      {
        path: PAGE_ADMIN_DASHBOARD.countries.relativePath,
        element: <ManageCountryPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.countries.create.absolutePath,
        element: <CreateCountryPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.countries.edit.absolutePath,
        element: <CreateCountryPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.categories.relativePath,
        element: <ManageCategoryPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.categories.create.absolutePath,
        element: <CreateCategoryPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.categories.edit.absolutePath,
        element: <CreateCategoryPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.users.relativePath,
        element: <ManageUserPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.users.create.absolutePath,
        element: <CreateUserPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.users.edit.absolutePath,
        element: <CreateUserPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.technologies.relativePath,
        element: <ManageTechnologyPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.technologies.create.absolutePath,
        element: <CreateTechnologyPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.technologies.edit.absolutePath,
        element: <CreateTechnologyPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.clients.relativePath,
        element: <ManageClientPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.clients.create.absolutePath,
        element: <CreateClientPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.clients.edit.absolutePath,
        element: <CreateClientPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.projects.relativePath,
        element: <ManageProjectPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.projects.create.absolutePath,
        element: <CreateProjectPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.projects.edit.absolutePath,
        element: <CreateProjectPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.userProjects.absolutePath,
        element: <ManageUserProjectsPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.userProjects.create.absolutePath,
        element: <CreateUserProjectsPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.userProjects.edit.absolutePath,
        element: <CreateUserProjectsPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.leads.relativePath,
        element: <ManageLeadPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.leads.create.absolutePath,
        element: <CreateLeadPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.leads.edit.absolutePath,
        element: <CreateLeadPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.leadCategories.relativePath,
        element: <ManageLeadCategoryPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.leadCategories.create.absolutePath,
        element: <CreateLeadCategoryPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.leadCategories.edit.absolutePath,
        element: <CreateLeadCategoryPage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.leadSources.relativePath,
        element: <ManageLeadSourcePage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.leadSources.create.absolutePath,
        element: <CreateLeadSourcePage />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.leadSources.edit.absolutePath,
        element: <CreateLeadSourcePage />
      }
    ]
  }
];

export default AdminDashboardRoutes;
