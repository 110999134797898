/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create side bar/drawer for admin dashboard pages.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo, useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Link,
  List,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

/* Relative Imports */
import { PAGE_ROOT } from 'routes/paths';
import SessionContext from 'context/SessionContext';
import Footer from 'components/Footer';
import WebsiteLogo from 'components/WebsiteLogo';
import MyAvatar from 'components/MyAvatar';
import Scrollbar from 'components/Scrollbar';

/* Local Imports */
import { SidebarItem } from '.';
import sidebarConfig from '../helper/sidebarConfig';
import styles from '../index.style';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {boolean} openMobile
 * @property {function} onMobileClose
 */
const propTypes = {
  openMobile: PropTypes.bool.isRequired,
  onMobileClose: PropTypes.func.isRequired
};

// ----------------------------------------------------------------------

/**
 * Side bar/drawer for all admin pages.
 *
 * @component
 * @param {boolean} openMobile - flag to check if drawer is open/close
 * @param {function} onMobileClose - callback function to change the state of openMobile
 * @returns {JSX.Element}
 */
function Sidebar({ openMobile, onMobileClose }) {
  /* Hooks */
  const theme = useTheme();
  const location = useLocation();
  const laptopDownMatches = useMediaQuery(theme.breakpoints.down('laptop'));
  const { isAuthenticated, user } = useContext(SessionContext);
  let sidebarPages = [];
  if (user.IsAdmin) {
    sidebarPages = [...sidebarConfig.admin, ...sidebarConfig.bdm];
  } else if (user.IsBDM) {
    sidebarPages = [...sidebarConfig.bdm];
  }
  /* Side-Effects */
  useEffect(() => {
    if (openMobile) {
      onMobileClose();
    }
  }, [location.pathname]);

  /* Hooks */
  const content = isAuthenticated && user && (
    <Stack height="100%">
      <Box sx={styles.logoContainer}>
        <WebsiteLogo />
      </Box>
      <Box sx={styles.userProfile}>
        <MyAvatar
          component={RouterLink}
          to={PAGE_ROOT.account.absolutePath}
          sx={styles.profilePicture}
        />
        <Box flex={1}>
          <Link
            component={RouterLink}
            underline="none"
            color="text.primary"
            to={PAGE_ROOT.account.absolutePath}
          >
            <Typography variant="subtitle1" noWrap>
              {user.FullName}
            </Typography>
          </Link>
          {user.IsAdmin && (
            <Typography variant="body2" color="text.secondary">
              Admin
            </Typography>
          )}
          {!user.IsAdmin && !!user.UserRoles?.length && (
            <Typography variant="body2" color="text.secondary">
              {user.UserRoles[0].RoleName}
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={styles.listContainer}>
        <Scrollbar>
          <List sx={styles.sidebarList}>
            {sidebarPages.map((item, index) => (
              <SidebarItem
                href={item.href}
                key={index}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </List>
        </Scrollbar>
      </Box>
      <Footer containerStyle={styles.footer} />
    </Stack>
  );

  /* Output */
  return (
    <>
      {laptopDownMatches ? (
        <Drawer
          anchor="left"
          variant="temporary"
          open={openMobile}
          onClose={onMobileClose}
          PaperProps={{
            sx: styles.sidebarDrawer
          }}
        >
          {content}
        </Drawer>
      ) : (
        <Box sx={styles.leftPanel}>{content}</Box>
      )}
    </>
  );
}

Sidebar.propTypes = propTypes;
export default memo(Sidebar);
