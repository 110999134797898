/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to define the logged in user routes.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { lazy } from 'react';

/* Relative Imports */
import UserLayout from 'layout/UserLayout';

/* Local Imports */
import { PAGE_ROOT } from './paths';
import AuthGuard from './guards/AuthGuard';

// ----------------------------------------------------------------------

/* User Module Imports */
const MyProjectsPage = lazy(() => import('views/user/MyProjects'));

const ProjectDetailPage = lazy(() => import('views/user/ProjectDetail'));

// ----------------------------------------------------------------------

/**
 * assign components to routes
 *
 * @returns {array}
 */
const UserRoutes = [
  {
    path: PAGE_ROOT.projects.relativePath,
    element: (
      <AuthGuard>
        <UserLayout>
          <MyProjectsPage />
        </UserLayout>
      </AuthGuard>
    )
  },
  {
    path: PAGE_ROOT.projectDetail.relativePath,
    element: (
      <AuthGuard>
        <UserLayout>
          <ProjectDetailPage />
        </UserLayout>
      </AuthGuard>
    )
  }
];

export default UserRoutes;
