/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to define the root routes.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { lazy } from 'react';

/* Relative Imports */
import AuthLayout from 'layout/AuthLayout';

/* Local Imports */
import { PAGE_ROOT } from './paths';
import UserGuard from './guards/UserGuard';

// ----------------------------------------------------------------------

/* Auth Module Imports */
const SignInPage = lazy(() => import('views/auth/SignIn'));
const ForgotPasswordPage = lazy(() => import('views/auth/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('views/auth/ResetPassword'));

// ----------------------------------------------------------------------

/**
 * assign components to routes
 *
 * @returns {array}
 */
const RootRoutes = [
  {
    path: '/',
    element: (
      <UserGuard>
        <AuthLayout>
          <SignInPage />
        </AuthLayout>
      </UserGuard>
    )
  },
  {
    path: PAGE_ROOT.signIn.relativePath,
    element: (
      <UserGuard>
        <AuthLayout>
          <SignInPage />
        </AuthLayout>
      </UserGuard>
    )
  },
  {
    path: PAGE_ROOT.forgotPassword.relativePath,
    element: (
      <UserGuard>
        <AuthLayout>
          <ForgotPasswordPage />
        </AuthLayout>
      </UserGuard>
    )
  },
  {
    path: PAGE_ROOT.resetPassword.relativePath,
    element: (
      <UserGuard>
        <AuthLayout>
          <ResetPasswordPage />
        </AuthLayout>
      </UserGuard>
    )
  }
];

export default RootRoutes;
