/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create Header for admin dashboard pages.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

/* Relative Imports */
import AccountPopover from 'components/AccountPopover';

/* Local Imports */
import styles from '../index.style';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {function} onMobileNavOpen
 */
const propTypes = {
  onMobileNavOpen: PropTypes.func.isRequired
};

// ----------------------------------------------------------------------

/**
 * Header for the admin dashboard pages
 *
 * @component
 * @param {function} onMobileNavOpen - callback function to handle open/close on click of side bar icon.
 * @returns {JSX.Element}
 */
function Header({ onMobileNavOpen }) {
  /* Hooks */
  const theme = useTheme();
  const laptopDownMatches = useMediaQuery(theme.breakpoints.down('laptop'));

  /* Output */
  return (
    <Container maxWidth={false} sx={styles.header}>
      {laptopDownMatches && (
        <IconButton
          color="inherit"
          onClick={onMobileNavOpen}
          sx={styles.menuIcon}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Box sx={styles.rightOptions}>
        <AccountPopover />
      </Box>
    </Container>
  );
}

Header.propTypes = propTypes;
export default memo(Header);
