/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create menu popover component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 18/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Popover } from '@mui/material';

/* Local Imports */
import styles from './index.style';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {node} children
 * @property {boolean} showIndicator
 * @property {object|function} contentStyle
 * @property {object|function} indicatorStyle
 */
const propTypes = {
  children: PropTypes.node.isRequired,
  showIndicator: PropTypes.bool,
  contentStyle: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  indicatorStyle: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

/**
 * @typedef defaultProps
 * @property {boolean} showIndicator
 * @property {object|function} contentStyle
 * @property {object|function} indicatorStyle
 */
const defaultProps = {
  showIndicator: false,
  contentStyle: {},
  indicatorStyle: {}
};

// ----------------------------------------------------------------------
/**
 * Displays some content on top of another
 *
 * @component
 * @param {node} children - contains data or component
 * @param {boolean} showIndicator - is used to show arrow
 * @param {object|function} contentStyle - is used for styling
 * @param {object|function} indicatorStyle - is used for styling the arrow
 *
 * @returns {JSX.Element}
 */
function MenuPopover({
  showIndicator,
  contentStyle,
  indicatorStyle,
  children,
  ...other
}) {
  /* Output */
  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      PaperProps={{
        sx: [styles.popover, contentStyle]
      }}
      {...other}
    >
      {showIndicator && <Box sx={[styles.arrowStyle, indicatorStyle]} />}
      {children}
    </Popover>
  );
}

MenuPopover.propTypes = propTypes;
MenuPopover.defaultProps = defaultProps;
export default memo(MenuPopover);
