/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create single item for side bar.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {object} icon
 * @property {string} title
 * @property {string} href
 */
const propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
};

// ----------------------------------------------------------------------

/**
 * Side bar item to show as tab.
 *
 * @component
 * @param {object} icon - icon to show for list item
 * @param {string} title - name of the tab in side bar
 * @param {string} href - redirect url of the tab in side bar
 * @returns {JSX.Element}
 */
function SidebarItem({ icon: Icon, title, href, ...other }) {
  /* Hooks */
  const { pathname } = useLocation();
  const navigate = useNavigate();

  /* Output */
  return (
    <ListItemButton
      selected={Boolean(href && pathname.includes(href))}
      onClick={() => navigate(href)}
      {...other}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: 'body2'
        }}
      />
    </ListItemButton>
  );
}

SidebarItem.propTypes = propTypes;
export default memo(SidebarItem);
