/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create routing from all the routes.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

/* Relative Imports */
import LoadingScreen from 'components/LoadingScreen';

/* Local Imports */
import RootRoutes from './rootRoutes';
import UserRoutes from './userRoutes';
import AdminDashboardRoutes from './adminDashboardRoutes';

// ----------------------------------------------------------------------
/* Merge Routes */
const routes = [...RootRoutes, ...UserRoutes, ...AdminDashboardRoutes];

/**
 * Create routing with the routes
 *
 * @returns {JSX.Element}
 */
function Routing() {
  const content = useRoutes(routes);
  return <Suspense fallback={<LoadingScreen />}>{content}</Suspense>;
}

export default Routing;
