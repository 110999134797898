/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create footer component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';

/* Local Imports */
import styles from './index.style';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {object|function} containerStyle
 */
const propTypes = {
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

/**
 * @typedef defaultProps
 * @property {object|function} containerStyle
 */
const defaultProps = {
  containerStyle: {}
};
// ----------------------------------------------------------------------

/**
 * Footer Component for the authentication pages.
 *
 * @component
 * @param {object|function} containerStyle - styling for chips container
 * @returns {JSX.Element}
 */
function Footer({ containerStyle }) {
  /* Output */
  return (
    <Box sx={[styles.rootStyle, containerStyle]}>
      <Typography variant="caption">
        © {new Date().getFullYear()} Techechelons Infosolution Pvt. Ltd. All
        rights reserved.
      </Typography>
    </Box>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;
export default memo(Footer);
