/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to define the services related to user.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */

/* Relative Imports */
import axiosInstance from 'config/axiosConfig';

// ----------------------------------------------------------------------

export const updateUserRequest = (userId, user) => {
  return axiosInstance
    .put(`/admin/user/UpdateUser/${userId}`, user)
    .then((response) => response.data);
};

export const updateUserActiveStatusRequest = (userId, data) => {
  return axiosInstance
    .put(`/admin/user/ChangeUserActiveStatus/${userId}`, data)
    .then((response) => response.data);
};

export const deleteUserRequest = (userId) => {
  return axiosInstance
    .delete(`/admin/user/DeleteUser/${userId}`)
    .then((response) => response.data);
};

export const getUserByIdRequest = (userId) => {
  return axiosInstance
    .get(`/admin/user/GetUserById/${userId}`)
    .then((response) => response.data);
};

export const getUsersRequest = () => {
  return axiosInstance
    .get('/admin/user/GetUsers')
    .then((response) => response.data);
};

export const getUserProfileRequest = (token) => {
  return axiosInstance
    .get('/admin/user/GetUserProfile', {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
    .then((response) => response.data);
};
