/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to define helper functions for authentication.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 16/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

/* Constants */
const cookieKey = 'te_projects_token';

// ----------------------------------------------------------------------
/**
 * function to set access token in cookies
 *
 * @param {string} accessToken - logged in user token
 * @param {boolean} isRememberMe - flag to remember/forgot user after session ends.
 * @returns {void}
 */
export const setAccessToken = (accessToken, isRememberMe) => {
  const cookieConfig = {
    path: '/',
    sameSite: true
  };
  if (isRememberMe) {
    const expiresDate = new Date(); // Now
    expiresDate.setDate(expiresDate.getDate() + 30); // Set now + 30 days as the new date

    cookieConfig.expires = expiresDate;
  } else {
    cookieConfig.expires = 0;
  }
  Cookies.set(cookieKey, accessToken, cookieConfig);
};

/**
 * function to remove access token from cookies
 *
 * @returns {void}
 */
export const removeAccessToken = () => {
  const cookieConfig = {
    path: '/',
    sameSite: true,
    expires: 0
  };
  Cookies.remove(cookieKey, cookieConfig);
};

/**
 * function to get access token from cookies
 *
 * @returns {string} - returns a access token from cookies
 */
export const getAccessToken = () => {
  return Cookies.get(cookieKey);
};

/**
 * function to validate the access toke by decoding the jwt token
 * @param {string} accessToken - logged in user token
 *
 * @returns {string} - returns a access token from cookies
 */
export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime ? decoded : null;
};
