/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to define the session context.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 22/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import React from 'react';
import PropTypes from 'prop-types';

/* Imports */
import { ROOT_PATH } from 'routes/paths';
import {
  getAccessToken,
  isValidToken,
  removeAccessToken,
  setAccessToken
} from 'helper/authHelper';
import { getUserProfileRequest } from 'services/user';

// ----------------------------------------------------------------------

/* Initial State */
const initialState = {
  isAuthenticated: false,
  authToken: null,
  user: null,
  LoginUser: async () => {},
  LogoutUser: () => {},
  UpdateProfilePicture: () => {}
};

/* Create Context */
const SessionContext = React.createContext(initialState);

// ----------------------------------------------------------------------
/**
 * @typedef propTypes
 * @property {node} children
 */
const propTypes = {
  children: PropTypes.node.isRequired
};

// ----------------------------------------------------------------------

/**
 * Component to define session provider
 *
 * @component
 * @param {node} children - contains the child components
 * @returns {JSX.Element}
 */
class Session extends React.Component {
  /* Constructor */
  constructor(props) {
    super(props);

    const accessToken = getAccessToken();
    const user = isValidToken(accessToken);

    this.state = {
      isAuthenticated: Boolean(accessToken && user),
      authToken: accessToken,
      user,
      LoginUser: (token, loggedInUser, rememberMe) => {
        setAccessToken(token, loggedInUser, rememberMe);
        const IsBDM = loggedInUser.UserRoles?.some((x) =>
          x.RoleName.toLowerCase().includes('business development')
        );
        const IsGuest = loggedInUser.UserRoles?.some((x) =>
          x.RoleName.toLowerCase().includes('guest')
        );
        this.setState({
          isAuthenticated: true,
          authToken: token,
          user: { ...loggedInUser, IsBDM, IsGuest }
        });
      },
      LogoutUser: () => {
        removeAccessToken();
        URL.revokeObjectURL(this.state.user.profilePic);
        this.setState((prevState) => ({
          ...prevState,
          isAuthenticated: false,
          authToken: null,
          user: null
        }));
        window.location.href = ROOT_PATH;
      },
      setUser: (loggedInUser) => {
        this.setState({ user: loggedInUser });
      }
    };
  }

  componentDidMount() {
    if (this.state.authToken) {
      this.getUserProfile();
    }
  }

  async getUserProfile() {
    try {
      const response = await getUserProfileRequest(this.state.authToken);
      if (response?.Status.ResponseCode === 200 && response?.User) {
        const IsBDM = response.User.UserRoles?.some((x) =>
          x.RoleName.toLowerCase().includes('business development')
        );
        const IsGuest = response.User.UserRoles?.some((x) =>
          x.RoleName.toLowerCase().includes('guest')
        );
        this.setState((prevState) => ({
          ...prevState,
          user: { ...response.User, IsBDM, IsGuest }
        }));
      }
    } catch {
      const user = isValidToken(this.state.authToken);
      if (user) {
        this.setState((prevState) => ({ ...prevState, user }));
      } else {
        this.state.LogoutUser();
      }
    }
  }

  /* Output */
  render() {
    return (
      <SessionContext.Provider value={this.state}>
        {this.props.children}
      </SessionContext.Provider>
    );
  }
}

Session.propTypes = propTypes;
export default SessionContext;
export const SessionProvider = Session;
export const SessionConsumer = SessionContext.Consumer;
