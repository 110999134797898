/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to define the theme context.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 22/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import React from 'react';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

/* Initial State */
const initialState = {
  themeMode: 'dark',
  switchMode: () => null
};

/* Create Context */
const ThemeContext = React.createContext(initialState);

// ----------------------------------------------------------------------
/**
 * @typedef propTypes
 * @property {node} children
 */
const propTypes = {
  children: PropTypes.node.isRequired
};

// ----------------------------------------------------------------------

/**
 * Component to define theme mode provider
 *
 * @component
 * @param {node} children - contains the child components
 * @returns {JSX.Element}
 */
class ThemeContextProvider extends React.Component {
  /* Constructor */
  constructor(props) {
    super(props);
    let selectedThemeMode = localStorage.getItem('themeMode');

    if (!selectedThemeMode) {
      selectedThemeMode = window.matchMedia('(prefers-color-scheme: dark)')
        .matches
        ? 'dark'
        : 'light';
      localStorage.setItem('themeMode', selectedThemeMode);
    }

    this.state = {
      themeMode: selectedThemeMode,
      switchMode: (newMode) => {
        this.setState((prevState) => ({
          ...prevState,
          themeMode: newMode
        }));
        localStorage.setItem('themeMode', newMode);
      }
    };
  }

  /* Output */
  render() {
    return (
      <ThemeContext.Provider value={this.state}>
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}

ThemeContextProvider.propTypes = propTypes;
export default ThemeContext;
export { ThemeContextProvider };
