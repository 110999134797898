/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create form dialog component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {boolean} open
 * @property {boolean} disabled
 * @property {string} title
 * @property {string} description
 * @property {boolean} isSubmitting
 * @property {string} submitText
 * @property {string} cancelText
 * @property {function} onSubmitAction
 * @property {function} onCancelAction
 * @property {node} children
 */
const propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  isSubmitting: PropTypes.bool,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  onSubmitAction: PropTypes.func.isRequired,
  onCancelAction: PropTypes.func.isRequired,
  children: PropTypes.node
};

/**
 * @typedef defaultProps
 * @property {boolean} open
 * @property {boolean} disabled
 * @property {string} description
 * @property {boolean} isSubmitting
 * @property {string} submitText
 * @property {string} cancelText
 */
const defaultProps = {
  open: false,
  disabled: false,
  description: null,
  isSubmitting: false,
  submitText: 'Save',
  cancelText: 'Cancel'
};

// ----------------------------------------------------------------------

/**
 * Popup dialog component for Form
 *
 * @Components
 * @param {boolean} open - to open and close the dialog
 * @param {boolean} disabled - to disable the button
 * @param {string} title - title for the form
 * @param {string} description - describing the form message
 * @param {boolean} isSubmitting - to show the loading for button
 * @param {string} submitText - text for 'Yes' button
 * @param {string} cancelText - text for 'No' button
 * @param {function} onSubmitAction - action for 'Yes' button
 * @param {function} onCancelAction -action for 'No' button
 * @param {node} children - contains data or component
 *
 * @returns {JSX.Element}
 */
function FormDialog({
  open,
  disabled = false,
  title,
  description,
  isSubmitting,
  submitText = 'Save',
  cancelText = 'Cancel',
  onSubmitAction,
  onCancelAction,
  children
}) {
  /* Output */
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {description && <DialogContentText>{description}</DialogContentText>}

        <Box pt={2}>{children}</Box>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="buttonCancel"
          variant="outlined"
          size="small"
          onClick={onCancelAction}
        >
          {cancelText}
        </Button>
        <LoadingButton
          data-testid="buttonSubmit"
          size="small"
          variant="contained"
          loading={isSubmitting}
          disabled={disabled}
          onClick={onSubmitAction}
        >
          {submitText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

FormDialog.propTypes = propTypes;
FormDialog.defaultProps = defaultProps;
export default memo(FormDialog);
