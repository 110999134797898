/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to define all the paths.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Constants */
const ROOT_PATH = '/';
const ROOT_ADMIN_DASHBOARD = 'admin-dashboard';
// const ROOT_USER_DASHBOARD = 'user-dashboard';

/* Home Page */
export { ROOT_PATH };

/* Root Pages */
export const PAGE_ROOT = {
  signIn: {
    relativePath: 'signin',
    absolutePath: '/signin'
  },
  signUp: {
    relativePath: 'signup',
    absolutePath: '/signup'
  },
  forgotPassword: {
    relativePath: 'forgot-password',
    absolutePath: '/forgot-password'
  },
  resetPassword: {
    relativePath: 'reset-password/:token',
    absolutePath: '/reset-password/:token'
  },
  account: {
    relativePath: 'my-account',
    absolutePath: '/my-account'
  },
  projects: {
    relativePath: 'projects',
    absolutePath: `/projects`
  },
  projectDetail: {
    relativePath: 'project-detail/:id',
    absolutePath: `/project-detail/:id`
  },
  notFound: {
    relativePath: 'not-found',
    absolutePath: '/not-found'
  }
};

/* Admin Dashboard Pages */
export const PAGE_ADMIN_DASHBOARD = {
  root: {
    relativePath: ROOT_ADMIN_DASHBOARD,
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}`
  },
  roles: {
    relativePath: 'roles',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/roles`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/roles/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/roles/edit/:id`
    }
  },
  countries: {
    relativePath: 'countries',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/countries`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/countries/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/countries/edit/:id`
    }
  },
  categories: {
    relativePath: 'categories',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/categories`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/categories/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/categories/edit/:id`
    }
  },
  users: {
    relativePath: 'users',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/users`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/users/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/users/edit/:id`
    }
  },
  technologies: {
    relativePath: 'technologies',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/technologies`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/technologies/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/technologies/edit/:id`
    }
  },
  clients: {
    relativePath: 'clients',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/clients`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/clients/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/clients/edit/:id`
    }
  },
  projects: {
    relativePath: 'projects',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/projects`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/projects/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/projects/edit/:id`
    }
  },
  userProjects: {
    relativePath: 'user-projects',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/user-projects`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/user-projects/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/user-projects/edit/:id`
    }
  },
  leads: {
    relativePath: 'leads',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/leads`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/leads/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/leads/edit/:id`
    }
  },
  leadCategories: {
    relativePath: 'lead-categories',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/lead-categories`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/lead-categories/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/lead-categories/edit/:id`
    }
  },
  leadSources: {
    relativePath: 'lead-sources',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/lead-sources`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/lead-sources/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/lead-sources/edit/:id`
    }
  }
};
